import Header from "./Header"
import Footer from "./Footer";
import './Home.css';
import react,{useEffect} from 'react';
import {useHistory} from "react-router-dom";
import Cards from '../common/Cards';
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteData, fetchEmployeeData, fetchAttendanceData } from '../actions/index';

const Home = () =>{

    const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(fetchAttendanceData());
    dispatch(fetchSiteData());
    dispatch(fetchEmployeeData());
  },[])

    const history = useHistory();

    const siteHome = () => {
        history.push("site/view")
    }
    const EmployeeHome = () => {
        history.push("employee/view")
    }
    const attendanceHome = () => {
        history.push("/attendance/view")
    }
    return(
        <>
        <Header /><br></br>
        <div className="container-fluid">
            <div className="row">
                <Cards title="Site Details" text="This is an option to manage site data" name="View Details" page={siteHome}/>
                <Cards title="Employee Details" text="This is an option to maintain Employee data" name="View Details" page={EmployeeHome}/>
                <Cards title="Attendance Details" text="This is an option to add site data" name="View Details" page={attendanceHome}/>
                <Cards title="Salary Details" text="This is an option to view salary details" name="View Details"/>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default Home;