import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { config } from "../../App";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Header from "../Header";
import * as XLSX from "xlsx";
import "./Attendance.css";
import { deleteAttendance } from '../../actions/index';

function SiteAttendance() {
  const { enqueueSnackbar } = useSnackbar();
  const [attendanceTable, setAttendanceTable] = useState([]);
  const token = localStorage.getItem("token");
  const history = useHistory();
  const dispatch = useDispatch();

  const { siteData, employeeData, attendanceData } = useSelector((state) => state);
  const [headerValues, setHeaderValues] = useState({ siteId: "", month: "", year: "" });

  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const years = [
    { value: "2024", label: "2024" },
    // Add more years as needed
  ];

  const daysInMonth = () => new Date(+headerValues.year, +headerValues.month, 0).getDate();
  const daysArray = Array.from({ length: daysInMonth() }, (_, i) => i + 1);

  function handleHeaderInput(e) {
    const { name, value } = e.target;
    setHeaderValues({ ...headerValues, [name]: value });
  }

  useEffect(() => {
    const data = attendanceData.attendanceData.filter(
      (item) =>
        item.siteId === headerValues.siteId &&
        new Date(item.attendanceDate).getMonth() + 1 === +headerValues.month &&
        new Date(item.attendanceDate).getFullYear() === +headerValues.year
    );
    const totalEmployees = Array.from(new Set(data.map((obj) => obj.empId))).map((id) =>
      data.find((a) => a.empId === id)
    );
    let empAttendance = [];
    for (let i = 0; i < totalEmployees.length; i++) {
      empAttendance.push({
        empId: totalEmployees[i].empId,
        empName: employeeData.employeeData.filter(emp => emp.empId === totalEmployees[i].empId)[0].empName,
        attendance: [],
        totalDayReport: { present: 0, halfDay: 0, absent: 0, paidLeave: 0, double: 0 }
      });
      for (let j = 0; j < data.length; j++) {
        if (totalEmployees[i].empId === data[j].empId) {
          const day = +data[j].attendanceDate.split("-")[2];
          if (data[j].dayReport === "present") {
            empAttendance[i].attendance[day] = "P";
            empAttendance[i].totalDayReport.present++;
          } else if (data[j].dayReport === "halfDay") {
            empAttendance[i].attendance[day] = "H";
            empAttendance[i].totalDayReport.halfDay++;
          } else if (data[j].dayReport === "absent") {
            empAttendance[i].attendance[day] = "A";
            empAttendance[i].totalDayReport.absent++;
          } else if (data[j].dayReport === "paidLeave") {
            empAttendance[i].attendance[day] = "PL";
            empAttendance[i].totalDayReport.paidLeave++;
          } else if (data[j].dayReport === "double") {
            empAttendance[i].attendance[day] = "D";
            empAttendance[i].totalDayReport.double++;
          }
        }
      }
    }
    setAttendanceTable(empAttendance);
  }, [headerValues, attendanceData, employeeData]);

  const handleDelete = async (deleteData, index) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete attendance of "${deleteData.empName}" for "${`${headerValues.year}-${headerValues.month}-${index + 1}`}"?`
    );
    if (confirmDelete) {
      try {
        const response = await axios.delete(`${config.endpoint}attendance/`, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: {
            empId: deleteData.empId,
            attendanceDate: `${headerValues.year}-${headerValues.month}-${index + 1 < 10 ? "0" + (index + 1) : index + 1}`,
          },
        });

        if (response.status === 204) {
          enqueueSnackbar("Attendance Deleted", { variant: "success" });
          dispatch(deleteAttendance(deleteData.empId, `${headerValues.year}-${headerValues.month}-${index + 1 < 10 ? "0" + (index + 1) : index + 1}`, deleteData.siteId));
        }
      } catch (e) {
        if (e.response && e.response.status === 400) {
          enqueueSnackbar(e.response.data.message, { variant: "error" });
        } else {
          enqueueSnackbar("Something went wrong in the backend", { variant: "error" });
        }
      }
    }
  };

  const downloadExcel = (siteName, siteAddress, attendanceMonth) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      ["Name:", siteName],
      ["Place:", siteAddress],
      ["Month:", attendanceMonth],
      [],
      ["Employee Name", "Total", ...daysArray.map(day => day.toString())]
    ]);

    const data = attendanceTable.map((item) => {
      const total = item.totalDayReport.present +
                    item.totalDayReport.halfDay * 0.5 +
                    item.totalDayReport.paidLeave +
                    item.totalDayReport.double * 2;
      return [
        item.empName,
        total,
        ...daysArray.map((day) => item.attendance[day] || ""),
      ];
    });

    XLSX.utils.sheet_add_aoa(ws, data, { origin: -1 });

    const applyCellStyle = (cell, bgColor) => {
      cell.s = {
        fill: {
          fgColor: { rgb: bgColor },
        },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
      };
    };

    data.forEach((row, rowIndex) => {
      daysArray.forEach((day, colIndex) => {
        const cellRef = XLSX.utils.encode_cell({ r: rowIndex + 4, c: colIndex + 2 });
        const cell = ws[cellRef];
        if (cell) {
          let bgColor = "FFFFFF";
          switch (cell.v) {
            case "P":
              bgColor = "90EE90";
              break;
            case "A":
              bgColor = "FF6347";
              break;
            case "H":
              bgColor = "FFFF99";
              break;
            case "PL":
              bgColor = "ADD8E6";
              break;
            case "D":
              bgColor = "D8BFD8";
              break;
            default:
              break;
          }
          applyCellStyle(cell, bgColor);
        }
      });
    });

    const headerCells = ["A5", "B5", ...daysArray.map((_, i) => XLSX.utils.encode_cell({ r: 4, c: i + 2 }))];
    headerCells.forEach(cellRef => {
      const cell = ws[cellRef];
      if (cell) {
        applyCellStyle(cell, "CCCCCC");
      }
    });

    const totalRow = ["Total", data.reduce((sum, row) => sum + row[1], 0), ...Array(daysArray.length).fill("")];
    XLSX.utils.sheet_add_aoa(ws, [totalRow], { origin: -1 });

    ws["!cols"] = [
      { wpx: Math.max(150, ...data.map(row => row[0]?.length * 10)) },
      { wpx: 100 },
      ...daysArray.map((_, colIndex) => ({
        wpx: Math.max(40, ...data.map(row => (row[colIndex + 2] || "").toString().length * 10))
      }))
    ];

    XLSX.utils.book_append_sheet(wb, ws, "Attendance");
    XLSX.writeFile(wb, `Attendance_${attendanceMonth}.xlsx`);
  };

  return (
    <>
      <Header />
      <Container fluid>
        <Row className="mt-3">
          <Col xs={12} md={4}>
            <h3 className="mb-3">Select Site</h3>
            <Form.Select name="siteId" value={headerValues.siteId} onChange={handleHeaderInput}>
              <option value="">Select an option</option>
              {siteData?.siteData.map((x) => (
                <option key={x.siteId} value={x.siteId}>
                  {x.siteName}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs={12} md={4}>
            <h3 className="mb-3">Month</h3>
            <Form.Select name="month" value={headerValues.month} onChange={handleHeaderInput}>
              <option value="">Select Month</option>
              {months.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs={12} md={4}>
            <h3 className="mb-3">Year</h3>
            <Form.Select name="year" value={headerValues.year} onChange={handleHeaderInput}>
              <option value="">Select Year</option>
              {years.map((year) => (
                <option key={year.value} value={year.value}>
                  {year.label}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12}>
            <div className="d-flex justify-content-end">
              <div style={{paddingRight:'2%', fontWeight:'bold'}}>
                {'Total -  '}
                {attendanceTable.reduce((total, item) => 
                  total + item.totalDayReport.present +
                  item.totalDayReport.halfDay * 0.5 +
                  item.totalDayReport.paidLeave +
                  item.totalDayReport.double * 2, 0)}
              </div>
              <Button variant="primary" onClick={()=>downloadExcel('siteName', 'siteAddress', 'attendanceMonth')}>
                Download Attendance Sheet
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="mt">
          <Col xs={12}>
            <div className="table-responsive">
              <table className="table table-bordered text-center">
                <thead>
                  <tr>
                  <th>S.No.</th>
                    <th>Employee Name</th>
                    {daysArray.map((day) => (
                      <th key={day}>{day}</th>
                    ))}
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {attendanceTable.map((item, index) => (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td>{item.empName}</td>
                      {daysArray.map((day, dayIndex) => (
                        <td
                          key={dayIndex}
                          className={item.attendance[day] || ""}
                          style={{
                            backgroundColor:
                              item.attendance[day] === "P"
                                ? "green"
                                : item.attendance[day] === "A"
                                ? "red"
                                : item.attendance[day] === "H"
                                ? "yellow"
                                : item.attendance[day] === "PL"
                                ? "blue"
                                : item.attendance[day] === "D"
                                ? "purple"
                                : "transparent",
                          }}
                          onClick={() => handleDelete(item, dayIndex)}
                        >
                          {item.attendance[day] || ""}
                        </td>
                      ))}
                      <td>
                        {item.totalDayReport.present +
                          item.totalDayReport.halfDay * 0.5 +
                          item.totalDayReport.paidLeave +
                          item.totalDayReport.double * 2}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SiteAttendance;
