import React from 'react'
import {useHistory} from "react-router-dom";
import Header from "../Header";
import Cards from '../../common/Cards';
import Footer from '../Footer';

function EmployeeHome() {

    const history = useHistory();

    const employeeRegistration = () => {
        history.push("/employeeRegistration")
    }
    return (
    <>
    <Header /><br></br>
    <div className="container-fluid">
        <div className="row">
            <Cards title="Register New Employee" text="This is an option to manage employee data" name="View Details" page={employeeRegistration}/>
            <Cards title="View/Manage Employee" text="This is an option to maintain Employee data" name="View Details" page=''/>
        </div>
    </div>
    <Footer />
    </>
    )
}

export default EmployeeHome
