export const FETCH_SITE_DATA_REQUEST = "FETCH_SITE_DATA_REQUEST";
export const FETCH_SITE_DATA_SUCCESS = "FETCH_SITE_DATA_SUCCESS";
export const FETCH_SITE_DATA_FAILURE = "FETCH_SITE_DATA_FAILURE";

export const FETCH_EMPLOYEE_DATA_REQUEST = "FETCH_EMPLOYEE_DATA_REQUEST";
export const FETCH_EMPLOYEE_DATA_SUCCESS = "FETCH_EMPLOYEE_DATA_SUCCESS";
export const FETCH_EMPLOYEE_DATA_FAILURE = "FETCH_EMPLOYEE_DATA_FAILURE";

export const FETCH_ATTENDANCE_DATA_REQUEST = "FETCH_ATTENDANCE_DATA_REQUEST";
export const FETCH_ATTENDANCE_DATA_SUCCESS = "FETCH_ATTENDANCE_DATA_SUCCESS";
export const FETCH_ATTENDANCE_DATA_FAILURE = "FETCH_ATTENDANCE_DATA_FAILURE";

export const DELETE_ATTENDANCE = "DELETE_ATTENDANCE";
export const UPDATE_ATTENDANCE = "UPDATE_ATTENDANCE";