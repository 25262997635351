import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { config } from "../../App";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import Header from '../Header';
import Footer from '../Footer';
import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";

const SiteTable = () => {
  const [sites, setSites] = useState([]);
  const token = localStorage.getItem("token");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { loading, siteData, siteError } = useSelector(state => state);

  useEffect(() => {
    setSites(siteData.siteData)
  }, [siteData]);

  const handleEdit = (siteId) => {
    // history.push(`/site/editSite/${siteId}`);
  };

  return (
    <>
      <Header />
      <div className="text-field-page">
        <h1>All Sites</h1>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={25} color="primary" />
          </Box>
        ) : (
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Site ID</th>
                <th>Site Name</th>
                <th>Site Address</th>
                <th>Total Employees</th>
                <th>Estimated Cost Per Month</th>
                <th>Compliance</th>
                <th>GST Percentage</th>
                <th>Contact Person</th>
                <th>Contact Person No</th>
                <th>Referral</th>
                <th>Is Active</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sites.map((site) => (
                <tr key={site.siteId}>
                  <td>{site.siteId}</td>
                  <td>{site.siteName}</td>
                  <td>{site.siteAddress}</td>
                  <td>{site.totalEmployees}</td>
                  <td>{site.estimatedCostPerMonth}</td>
                  <td>{site.compliance}</td>
                  <td>{site.gstPercentage}</td>
                  <td>{site.contactPerson}</td>
                  <td>{site.contactPersonNo}</td>
                  <td>{site.referral}</td>
                  <td>{site.isActive ? "Yes" : "No"}</td>
                  <td>
                    <Button
                      variant="contained"
                      onClick={() => handleEdit(site.siteId)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <Footer />
    </>
  );
};

export default SiteTable;
