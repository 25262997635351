import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './RegisterSite.css';
import Header from '../Header';
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { config } from "../../App";
import Footer from '../Footer';
import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

const RegisterSite = () => {
  const companyId = localStorage.getItem("companyId");
  const [formData, setFormData] = useState({
    companyId: companyId,
    siteId: '',
    siteName: '',
    siteAddress: '',
    totalEmployees: '',
    estimatedCostPerMonth: '',
    compliance: '',
    gstPercentage: '',
    contactPerson: '',
    contactPersonNo: '',
    referral: '',
    isActive: false
  });
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(
        `${config.endpoint}siteData/registerSite`,
        formData,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } }
      );
      setLoading(false);
      if (response.status === 201) {
        enqueueSnackbar("Site Registered", { variant: "success" });
        history.push("/home");
      }
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status === 400) {
        enqueueSnackbar(e.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Something went wrong in the backend", { variant: "error" });
      }
    }
  };

  return (
    <>
      <Header />
      <div className="text-field-page">
        <h1>Register Site</h1>
        <form onSubmit={handleSubmit}>
          <div className="text-field-container">
            <div className="text-field">
              <span>Site Name</span>
              <input
                type="text"
                name="siteName"
                className="form-control"
                required
                value={formData.siteName}
                onChange={handleChange}
              />
            </div>
            <div className="text-field">
              <span>Site Address</span>
              <input
                type="text"
                name="siteAddress"
                className="form-control"
                required
                value={formData.siteAddress}
                onChange={handleChange}
              />
            </div>
            <div className="text-field">
              <span>Total Employees</span>
              <input
                type="number"
                name="totalEmployees"
                className="form-control"
                required
                min="0"
                value={formData.totalEmployees}
                onChange={handleChange}
              />
            </div>
            <div className="text-field">
              <span>Estimated Cost Per Month</span>
              <input
                type="number"
                name="estimatedCostPerMonth"
                className="form-control"
                required
                min="0"
                value={formData.estimatedCostPerMonth}
                onChange={handleChange}
              />
            </div>
            <div className="text-field">
              <span>Compliance</span>
              <select
                name="compliance"
                className="form-control"
                required
                value={formData.compliance}
                onChange={handleChange}
              >
                <option value="">Select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="text-field">
              <span>GST Percentage</span>
              <input
                type="number"
                name="gstPercentage"
                className="form-control"
                required
                min="0"
                max="100"
                value={formData.gstPercentage}
                onChange={handleChange}
              />
            </div>
            <div className="text-field">
              <span>Contact Person</span>
              <input
                type="text"
                name="contactPerson"
                className="form-control"
                value={formData.contactPerson}
                onChange={handleChange}
              />
            </div>
            <div className="text-field">
              <span>Contact Person No</span>
              <input
                type="text"
                name="contactPersonNo"
                className="form-control"
                pattern="\d{10}"
                title="Phone Number is not valid"
                value={formData.contactPersonNo}
                onChange={handleChange}
              />
            </div>
            <div className="text-field">
              <span>Referral</span>
              <input
                type="text"
                name="referral"
                className="form-control"
                value={formData.referral}
                onChange={handleChange}
              />
            </div>
            <div className="text-field">
              <span>Is Active</span>
              <input
                type="checkbox"
                name="isActive"
                checked={formData.isActive}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mt-4">
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size={25} color="primary" />
              </Box>
            ) : (
              <Button type='submit' className="button" style={{width:'15vw'}} variant="contained">
                Register Site
              </Button>
            )}
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default RegisterSite;
