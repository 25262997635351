import './App.css';
import react,{useEffect} from 'react';
import { Route, Switch } from "react-router-dom";
import Login from './components/Login'
import Register from './components/Register';
import Home from './components/Home'
import EmployeeDetails from './components/EmployeePage/EmployeeDetails';
import AddSiteDetail from './components/SitePage/RegisterSiteDetail'
import AttendanceRegister from './components/AttendancePage/AttendanceRegister';
import AttendanceDetail from './components/AttendancePage/SiteAttendance'
import SiteHome from './components/SitePage/SiteHome';
import EmployeeAttendance from './components/AttendancePage/EmployeeAttendance';
import EmployeeHome from './components/EmployeePage/EmployeeHome';
import AttendanceHome from './components/AttendancePage/AttendanceHome';
import SiteTable from './components/SitePage/SiteTable';
import EditSite from './components/SitePage/EditSite'

export const config = {
  endpoint: `https://security-backend.onrender.com/v1/`,
  // endpoint: `http://localhost:8080/v1/`,
};

function App() {  
  return (
    <Switch>
    <div className="App">
      <Route exact path="/">
        <Login />
      </Route>
      <Route exact path="/home">
        <Home />
      </Route>
      <Route exact path="/register">
        <Register />
      </Route>     
      
      {/* Site Routes */}
      <Route exact path="/site/view">
        <SiteHome />
      </Route>
      <Route exact path="/site/addSiteDetail">
        <AddSiteDetail />
      </Route>
      <Route exact path="/site/manageSite">
        <SiteTable />
      </Route>
      <Route exact path="/site/editSite/:siteId">
        <EditSite />
      </Route>

      {/* Employee Routes */}
      <Route exact path="/employee/view">
        <EmployeeHome />
      </Route>
      <Route exact path="/employeeRegistration">
        <EmployeeDetails />
      </Route>   

      { /* Attendance Routes */}
      <Route exact path="/attendance/view">
        <AttendanceHome />
      </Route>
      <Route exact path="/attendanceRegister">
        <AttendanceRegister />
      </Route>
      <Route exact path="/siteAttendance">
        <AttendanceDetail />
      </Route>
      <Route exact path="/employeeAttendance">
        <EmployeeAttendance />
      </Route>
    </div>
    </Switch>
  );
}

export default App;
