import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { config } from "../../App";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Header from "../Header";
import Footer from '../Footer';
import "./Attendance.css";
import { updateAttendance } from "../../actions";
import { gridPaginatedVisibleSortedGridRowIdsSelector } from "@mui/x-data-grid";

function Attendance() {
  const { siteData, employeeData, attendanceData } = useSelector(state => state);
  const [filteredData, setFilteredData] = useState([]);
  const [attendanceForm, setAttendanceForm] = useState([]);
  const [headerValues, setHeaderValues] = useState({ siteId: "", attendanceDate: "" });
  const [loading, setLoading] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const token = localStorage.getItem("token");

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();

  const postAttendanceData = async (formValues) => {
    if (!validateInput(headerValues, attendanceForm)) { return; }

    const filteredFormValues = formValues.filter(item => item !== null || item !== undefined || item !== '');
    try {
      setLoading(true);
      const response = await axios.put(`${config.endpoint}attendance/daily`, { formValues: filteredFormValues }, {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      setLoading(false);
      if (response.status === 200) {
        enqueueSnackbar("Attendance Marked", { variant: "success" });
        dispatch(updateAttendance(response.data));
      }
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.status === 400) {
        enqueueSnackbar(e.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Something went wrong in the backend", { variant: "error" });
      }
    }
  };

  useEffect(()=>{
    setAttendanceForm([])
  },[headerValues.attendanceDate])

  const validateInput = (headerValues, attendanceForm) => {
    if (!headerValues.attendanceDate) {
      enqueueSnackbar("Attendance Date is a required field", { variant: "warning" });
      return false;
    }
    return true;
  };

  function handleEmployeeSelect(e) {
    setSelectedEmployee(e.target.value);
    const filtered = employeeData.employeeData.filter(emp => emp.empId === e.target.value && emp.isActive === true)[0];
    filteredData.push({
      empId: filtered.empId, 
      siteId: headerValues.siteId,
      dayReport: ''
    });
  }

  function handleUserInput(e, i, item) {
    const objectToUpdate = filteredData.find(obj => obj.empId === item.empId);
    if (objectToUpdate && e.target.name === "dayReport") {
      objectToUpdate.dayReport = e.target.value;
    }
    const { name, value } = e.target;
    const list = [...attendanceForm];
    list[i] = { [name]: value }; 
    list[i].empId = item.empId;
    list[i].siteId = headerValues.siteId;
    list[i].attendanceDate = headerValues.attendanceDate;
    setAttendanceForm(list);
  }

  function handleHeaderInput(e) {
    const { name, value } = e.target;
    setHeaderValues({ ...headerValues, [name]: value });
  }

  function markAllAttendance(status) {
    const updatedForm = filteredData.map(emp => ({
      empId: emp.empId,
      siteId: headerValues.siteId,
      attendanceDate: headerValues.attendanceDate,
      dayReport: status
    }));
    setFilteredData(updatedForm);
    setAttendanceForm(updatedForm);
  }
  useEffect(() => {
    if (headerValues.siteId && headerValues.attendanceDate) {
      const filteredEmployees = employeeData.employeeData.filter(emp => 
        emp.siteId === headerValues.siteId && emp.isActive === true
      );

      const filteredAttendance = attendanceData.attendanceData.filter(att => 
        att.siteId === headerValues.siteId && att.attendanceDate === headerValues.attendanceDate
      );

      const combinedFilteredData = filteredEmployees.map(emp => {
        const empAttendance = filteredAttendance.find(att => att.empId === emp.empId);
        return {
          empId: emp.empId,
          empName: emp.empName,
          siteId: headerValues.siteId,
          siteName: siteData.siteData.find(site => site.siteId === headerValues.siteId)?.siteName || '',
          dayReport: empAttendance?.dayReport || ''
        };
      });

      const additionalEmployees = filteredAttendance.filter(att => 
        !filteredEmployees.some(emp => emp.empId === att.empId)
      ).map(att => ({
        empId: att.empId,
        empName: employeeData.employeeData.find(emp => emp.empId === att.empId)?.empName || '',
        siteId: headerValues.siteId,
        siteName: siteData.siteData.find(site => site.siteId === headerValues.siteId)?.siteName || '',
        dayReport: att.dayReport
      }));
      setFilteredData([...combinedFilteredData, ...additionalEmployees]);
    }
  }, [headerValues, employeeData, attendanceData, siteData]);

  return (
    <>
      <Header />
      <Container fluid>
        <Row className="mt-3">
          <Col xs={12} md={4}>
            <h3 className="mb-3">Select Site</h3>
            <Form.Control as="select" name="siteId" value={headerValues.siteId} onChange={handleHeaderInput}>
              <option value="">Select an option</option>
              {siteData?.siteData.map((x) => <option value={x.siteId} key={x.siteId}>{x.siteName} - {x.siteId}</option>)}
            </Form.Control>
          </Col>
          <Col xs={12} md={4}>
            <h3 className="mb-3">Date</h3>
            <Form.Control type="date" name="attendanceDate" value={headerValues.attendanceDate} onChange={handleHeaderInput} />
          </Col>
          <Col xs={12} md={4}>
        <Button variant="success" onClick={() => markAllAttendance('present')} style={{ margin: '10px' }}>
          Mark All Present
        </Button>
        <Button variant="danger" onClick={() => markAllAttendance('absent')} style={{ margin: '10px' }}>
          Mark All Absent
        </Button>
      </Col>
        </Row>
      </Container>
      <div className="table-responsive">
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
            <th style={{ width: '120px' }}>S.No.</th>
              <th style={{ width: '120px' }}>Employee ID</th>
              <th style={{ width: '200px' }}>Name</th>
              <th style={{ width: '200px' }}>Working Site</th>
              <th style={{ width: '250px' }}>Day Report</th>
            </tr>
          </thead>
          <tbody>
            {filteredData?.map((item, i) => (
              <tr key={i}>
                <td>{i+1}</td>
                <td>{item.empId}</td>
                <td>{employeeData.employeeData.filter(emp=>item.empId===emp.empId)[0].empName}</td>
                <td>{siteData.siteData.filter(site=>item.siteId===site.siteId)[0].siteName}</td>
                <td>
                  <div className="d-flex flex-column flex-md-row justify-content-md-between">
                    <div className="btn-group" role="group">
                      <Button variant={item.dayReport === 'present' ? "success" : "secondary"} onClick={(e) => handleUserInput(e, i, item)} name="dayReport" value="present">Present</Button>
                      <Button variant={item.dayReport === 'absent' ? "danger" : "secondary"} onClick={(e) => handleUserInput(e, i, item)} name="dayReport" value="absent">Absent</Button>
                      <Button variant={item.dayReport === 'halfDay' ? "warning" : "secondary"} onClick={(e) => handleUserInput(e, i, item)} name="dayReport" value="halfDay">Half Day</Button>
                      <Button variant={item.dayReport === 'double' ? "primary" : "secondary"} onClick={(e) => handleUserInput(e, i, item)} name="dayReport" value="double">Double</Button>
                      <Button variant={item.dayReport === 'paidLeave' ? "success" : "secondary"} onClick={(e) => handleUserInput(e, i, item)} name="dayReport" value="paidLeave">Paid Leave</Button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h6 style={{marginRight:'77%'}}>Select Employee from other site</h6>
        <Form.Control as="select" style={{width:'20%'}} value={selectedEmployee} onChange={handleEmployeeSelect}>
          <option value="">Select Employee</option>
          {employeeData.employeeData.map((emp) => {
            const isPresentInFilteredData = filteredData.some(filteredEmp => filteredEmp.empId === emp.empId);
            if (!isPresentInFilteredData) {
              return (
                <option key={emp.empId} value={emp.empId}>{`${emp.empName} - ${emp.empId}`}</option>
              );
            }
            return null;
          })}
        </Form.Control>
        <div className="text-center">
          <Button variant="success" onClick={() => postAttendanceData(attendanceForm)} disabled={loading}>
            {loading ? "Saving..." : "Save Attendance"}
          </Button>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Attendance;
